<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row dense>
                <v-col cols="12">
                    <!-- 绿色框 -->
                    <v-alert :dismissible="false" class="green white--text" dark border="left" icon="fas fa-satellite-dish">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10" class="pa-0">
                                <span>Signals</span>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="px-2">
                    <v-row>
                        <v-col cols="auto">
                            <!-- 选择交易对 -->
                            <v-btn small tile class="green white--text" @click="symbol='all'">ALL</v-btn>                    
                        </v-col>
                        <v-col cols="auto">
                            <v-select class="fs-12" dense v-model="symbol" label="Symbols" :items="signalSymbols" item-text="label" item-value="value"></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <!-- 添加新的信号 -->
                        <v-col cols="auto">
                             <v-btn small tile color="green white--text" @click="newSignal">New Signal</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pt-0 px-2">
                    <v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <!-- 表头 -->
                    <v-data-table dense :headers="SignalHeaders" :items="signals" hide-default-footer v-if="!loading">
                        <!-- 表头上侧 -->
                        <!-- <template v-slot:top>
                            <v-toolbar flat color="white">
                                <span >
                                    <v-btn small tile class="green white--text" @click="symbol='all'">ALL</v-btn>
                                </span>
                                <span class="ml-5 mt-3 align-self-center">
                                    <v-select class="fs-12"  dense v-model="symbol" label="Symbols" :items="signalSymbols" item-text="label" item-value="value">
                                    </v-select>
                                </span>
                                <v-spacer></v-spacer>
                                <div class="text-center">
                                    <v-btn small tile color="green white--text" @click="newSignal">New Signal</v-btn>
                                </div>
                            </v-toolbar>
                            <v-divider />
                        </template> -->
                        <!-- 表数据 -->
                        <template v-slot:body="{}">
                            <tbody>
                                <tr v-for="(signal,index) in signals" :key="signal.id">
                                    <td>{{(indexNo-1)* pageSize +(index+1)}}</td>
                                    <td>{{ signal.symbol }}</td>
                                    <td :class="['long'==signal.side? 'green--text':'red--text']">{{ toFirstLetterUpperCase(signal.side) }}</td>
                                    <td>{{ toFirstLetterUpperCase(signal.timeInterval) }}</td>
                                    <td class="text-right">{{ common.formatNum(signal.entryPrice)}}</td>
                                    <td class="text-right" >{{ signal.trailingStop == 0 ? '--': common.formatNum(signal.trailingStop)}}</td>
                                    <td class="text-right">{{ 0 == signal.exitPrice ? '--': common.formatNum(signal.exitPrice)}}</td>
                                    <td :class="signal.triggered?'black--text':'grey--text'">{{ signal.triggered ? "Yes":"No" }}</td>
                                    <td>{{ toFirstLetterUpperCase(signal.status) }}</td>
                                    <td>{{ common.dateFormat(signal.timestamp) }}</td>
                                    <td>{{ signal.status == 'closed' ? (signal.closedTimestamp == 0 ? common.dateFormat(signal.timestamp) : common.dateFormat(signal.closedTimestamp)) : "--"}}</td>
                                    <td>
                                        <v-btn icon @click="editSignal(signal)" small>
                                           <v-icon small>
                                               fas fa-edit
                                           </v-icon>
                                        </v-btn>
                                        <v-btn icon @click="deleteSignal(signal)" small class="ml-2">
                                            <v-icon small>fas fa-trash-alt</v-icon>
                                        </v-btn>
                                    </td>
                                    <!-- <v-icon @click="editSignal(signal)" class="ml-4 mt-2"
                                        v-if="'closed' != signal.status">edit</v-icon> -->
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider v-if="!loading"></v-divider>
                    <!-- 底部分页 -->
                    <template>
                        <div class="text-xs-center mt-5" v-if="totalElements>0">
                            <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="green" circle v-model="pageNo" :length="totalPage" @input="getSignalsBySymbol" :total-visible="15">
                            </v-pagination>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <!-- 编辑的弹出框 -->
        <v-dialog v-model="dialog" persistent max-width="500px">
            <!-- 模态框 -->
            <v-card>
                <v-card-title>
                    <span class="fs-24">{{ formTitle }}</span>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDialog" icon><v-icon>fas fa-times</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-xl>
                        <v-form ref="signalForm" v-model="signalFormValid">
                            <v-row dense>
                                <!-- 交易对 -->
                                <v-col>
                                    <v-select v-model="signalForm.symbol" :disabled="editDisable" :items="intervalSymbols" label="Symbol" :rules="[v => !!v || 'Symbol is required']" required></v-select>
                                </v-col>
                                <!-- 周期 -->
                                <v-col>
                                    <v-select v-model="signalForm.timeInterval" :disabled="editDisable" :items="signalIntervals" label="Interval" :rules="[v => !!v || 'Interval is required']"></v-select>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- 方向 -->
                                <v-col>
                                    <v-select v-model="signalForm.side" :disabled="editDisable" :items="side" label="Side" :rules="[v => !!v || 'Side is required']"></v-select>
                                </v-col>
                                <!-- 进入价 -->
                                <v-col>
                                    <v-text-field v-model="signalForm.entryPrice" :disabled="editDisable" label="Entry Price" :rules="formTitle == 'New Signal'? numberRules: noneRules"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- 追踪 -->
                                <v-col v-if="'Edit Signal' == formTitle" >
                                    <v-text-field v-model="signalForm.trailingStop" :disabled="editDisable" label="Trailing Stop"></v-text-field>
                                </v-col>
                                <!-- 退出价 -->
                                <v-col v-if="'Edit Signal' == formTitle" >
                                    <v-text-field v-model="signalForm.exitPrice" label="Exit Price" :rules="signalForm.status=='open' ? noneRules:numberRules" :disabled="signalForm.status=='open'"></v-text-field>
                                </v-col>
                                <!-- 状态 -->
                                <v-col v-if="'Edit Signal' == formTitle">
                                    <v-select v-model="editStatus" :items="status" label="Status" :rules="[v => !!v || 'Status is required']"></v-select>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- 止盈 -->
                                <v-col>
                                    <span>Take Profit Active:{{signalForm.takeProfitActive}}</span>
                                    <v-switch v-model="takeProfitActive" color="green"></v-switch>
                                </v-col>
                                <v-col>
                                    <span>Take Profit Level:</span>
                                    <v-text-field v-model="takeProfitLevel" type="number" min="0" max="100" suffix="%" :rules="[v => !!v || 'This field is required']" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- 止损 -->
                                <v-col>
                                    <span>Stop Loss Active:{{signalForm.stopLossActive}}</span>
                                    <v-switch v-model="stopLossActive" color="green"></v-switch>
                                </v-col>
                                <v-col>
                                    <span>Stop Loss Level:</span>
                                    <v-text-field v-model="stopLossLevel" type="number" min="0" max="100" suffix="%" :rules="[v => !!v || 'This field is required']" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- 触发 -->
                                <v-col>
                                    <span>Triggered: {{signalForm.triggered}}</span>
                                    <v-switch v-model="signalForm.triggered" color="green"></v-switch>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn class="blue--text" text @click="closeDialog">Cancel</v-btn> -->
                    <v-btn tile class="green--text" @click="closeDialog" text >Cancel</v-btn>
                    <v-btn tile class="blue--text" text @click="saveSignal(formTitle)" :disabled="!signalFormValid" :loading="signalLoading"> Save </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 删除的弹出框 -->
        <v-dialog v-model="deleteSignalDialog" persistent max-width="500px">
            <v-card>
            <v-card-title>
                <span>Delete {{ deleteSignalObj.timeInterval + ' ' + deleteSignalObj.symbol}} ?</span>
                <v-spacer></v-spacer>
                <v-btn @click="closeDeleteDialog()" icon><v-icon>fas fa-times</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col>Side:
                        <span :class=" deleteSignalObj.side == 'long' ? 'green--text' : 'red--text'">{{toFirstLetterUpperCase(deleteSignalObj.side)}}</span>
                    </v-col>
                    <v-col>Entry Price:
                        <span>{{deleteSignalObj.entryPrice}}</span>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>Trailing Stop:
                        <span>{{deleteSignalObj.trailingStop}}</span>
                    </v-col>
                    <v-col>Exit Price:
                        <span>{{deleteSignalObj.exitPrice}}</span>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>Triggered:
                        <span>{{deleteSignalObj.triggered ? "Yes" : "No"}}</span>
                    </v-col>
                    <v-col>Status:
                        <span>{{toFirstLetterUpperCase(deleteSignalObj.status)}}</span>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>Open Date:
                        <span>{{common.dateFormat(deleteSignalObj.timestamp)}}</span>
                    </v-col>
                    <v-col>Closed Date:
                        <span>{{ deleteSignalObj.status == "closed" ? (deleteSignalObj.closedTimestamp == 0 ? common.dateFormat(deleteSignalObj.timestamp) : common.dateFormat(deleteSignalObj.closedTimestamp)) : "--"}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile class="green--text" text @click="closeDeleteDialog()"> Cancel </v-btn>
                <v-btn tile class="red--text" text @click="deleteSignalConfirm(deleteSignalObj.id)"> Delete </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                dialog: false,
                SignalHeaders: [
                    {
                        text: 'No',
                        value: 'no',
                        sortable: false,
                        align:'left'
                    },
                    {
                        text: 'Symbol',
                        value: 'symbol',
                        sortable: false,
                        align:'left'
                    },
                    {
                        text: 'Side',
                        value: 'side',
                        sortable: false,
                        align:'left'
                    },
                    {
                        text: 'Interval',
                        value: 'interval',
                        sortable: false,
                        align:'left'
                    },
                    {
                        text: 'Entry Price',
                        value: 'entryPrice',
                        sortable: false,
                        align:'right'
                    },
                    {
                        text: 'Trailing Stop',
                        value: 'trailingStop',
                        sortable: false,
                        align:'right'
                    },
                    {
                        text: 'Exit Price',
                        value: 'exitPrice',
                        sortable: false,
                        align:'right'
                    },
                    {
                        text: 'Triggered',
                        value: 'triggered',
                        sortable: false
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        sortable: false,
                        align:'left'
                    },
                    {
                        text: 'Open Date',
                        value: 'timestamp',
                        sortable: false,
                        align:'left'
                    },
                    {
                        text: 'Closed Date',
                        value: 'closedTimestamp',
                        sortable: false,
                        align:'left'
                    },
                    {
                        text: 'Action',
                        value: 'action',
                        sortable: false
                    },
                ],
                formTitle: "",
                signals: [],
                side: ["long", "short"],
                status: ["open", "closed"],
                editDisable: false,
                signalFormValid: true,
                takeProfitActive: true,
                takeProfitLevel: 0,
                stopLossActive: true,
                stopLossLevel: 0,
                signalForm: {
                    symbol: null,
                    timeInterval: null,
                    side: null,
                    entryPrice: 0.0,
                    triggered: true,
                    takeProfitActive: true,
                    takeProfitLevel: 0,
                    stopLossActive: true,
                    stopLossLevel: 0,
                },
                numberRules: [
                    v => !!v || 'This field is required.',
                    v => /^\d+\.\d+$/.test(v) || 'This field is not a decimal.'
                ],
                noneRules:[],
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 25,
                symbol: "all",
                signalLoading:false,
                indexNo:0,
                editStatus:"open",
                loading:true,
                // 删除确认弹窗
                deleteSignalDialog:false,
                deleteSignalObj:{},
            }
        },
        computed: {
            ...mapGetters(['user', 'signalSymbols', 'intervalSymbols', 'signalIntervals']),
        },
        watch: {
            symbol(newVal,oldVal){
                if(newVal!=oldVal){
                    this.pageNo = 1;
                    this.getSignalsBySymbol(1);
                }
            },
            takeProfitLevel(newVal,oldVal){
                if (newVal < 0 || newVal > 100) {
                    this.takeProfitLevel = 0;
                    this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
                }else{
                    this.takeProfitLevel = Number(newVal);
                }
                this.signalForm.takeProfitLevel = newVal / 100;
            },
            stopLossLevel(newVal,oldVal){
                if (newVal < 0 || newVal > 100) {
                    this.stopLossLevel = 0;
                    this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
                }else{
                    this.stopLossLevel = Number(newVal);
                }
                this.signalForm.stopLossLevel = newVal / 100;
            },
            takeProfitActive(newVal,oldVal){
                this.signalForm.takeProfitActive = newVal;
            },
            stopLossActive(newVal,oldVal){
                this.signalForm.stopLossActive = newVal;
            },
            editStatus(newVal,oldVal){
                if(newVal == 'open'){
                    this.signalForm.status = 'open'
                    this.signalForm.exitPrice = 0
                }else{
                    this.signalForm.status = 'closed'
                }
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
        },
        created() {
            // this.getSignals(1);
            this.getSignalsBySymbol(1);
        },
        methods: {
            // 获取信号
            getSignalsBySymbol(pageNo) {
                this.signals = [];
                this.loading = true;
                let api = config.baseUrl + '/signal/private/getSignalHistoryListBySymbol/'+this.symbol+'/'+ (pageNo - 1) + '/' + this.pageSize
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.signals = response.data.result.signalHistories;
                            this.totalElements = response.data.result.totalElements;
                            this.totalPage = Math.ceil(response.data.result.totalElements / this.pageSize);
                            this.indexNo = pageNo;
                            this.loading = false;
                        }
                    },
                )
            },
            // 打开编辑信号弹窗
            editSignal(signal) {
                this.formTitle = "Edit Signal";
                this.editDisable = true;
                this.dialog = true;
                this.signalForm = JSON.parse(JSON.stringify(signal));
                if(this.signalForm.status == "open"){
                    this.signalForm.exitPrice = 0;
                }
                this.takeProfitLevel = this.signalForm.takeProfitLevel * 100;
                this.stopLossLevel = this.signalForm.stopLossLevel * 100;
                this.takeProfitActive = this.signalForm.takeProfitActive;
                this.stopLossActive = this.signalForm.stopLossActive;
                this.editStatus = this.signalForm.status; 
            },
            // 打开添加信号弹窗
            newSignal() {
                this.editDisable = false;
                this.formTitle = "New Signal";
                this.signalForm.triggered = true;
                this.signalForm.takeProfitActive = true;
                this.signalForm.stopLossActive = true;
                this.takeProfitActive = this.signalForm.takeProfitActive;
                this.stopLossActive = this.signalForm.stopLossActive;
                this.signalForm.status = 'open';
                this.dialog = true;
            },
            // 是否存在symbol的status为open的信号
            // isExistSignal() {
            //     let symbol = this.signalForm.symbol
            //     let param = {
            //         symbol: symbol
            //     }
            //     let api = config.baseUrl + '/signal/private/getSignalCountBySymbol?symbol=' + symbol;
            //     this.$http.get(api, param).then(response => {
            //         if (response.data.code == 200) {
            //             this.addSignal();
            //         } else if (response.data.code == 204) {
            //             this.$store.dispatch('snackbarMessageHandler',"存在交易对" + symbol + "状态为open的信号,请关闭后再添加");
            //             this.signalLoading = false;
            //         } else {
            //             this.closeDialog();
            //             this.$store.dispatch('snackbarMessageHandler', response.data.message);
            //         }
            //     })
            // },
            // 保存信号
            saveSignal(formTitle) {
                this.signalLoading = true;
                if ("New Signal" == formTitle) {
                    this.addSignal();
                }
                if ("Edit Signal" == formTitle) {
                    this.updateSignal();
                }
            },
            // 添加信号
            addSignal() {
                if (this.$refs.signalForm.validate()) {
                    let api = config.baseUrl + '/signal/admin/private/addSignal';
                    this.$http.post(api, this.signalForm).then(response => {
                        let data = response.data;
                        if (data.code == 200) {
                            this.$store.dispatch('snackbarMessageHandler', "添加成功");
                            this.getSignalsBySymbol(1);
                            this.closeDialog();
                        } else {
                            this.signalLoading = false;
                            this.$store.dispatch('snackbarMessageHandler', data.message);
                        }
                    }, error => {
                        this.$store.dispatch('snackbarMessageHandler', "Error");
                        this.signalLoading = false;
                    });
                }
            },
            // 更新信号
            updateSignal() {
                if (this.$refs.signalForm.validate()) {
                    let api = config.baseUrl + '/signal/admin/private/updateSignal';
                    this.$http.post(api, this.signalForm).then(response => {
                        let data = response.data;
                        if (data.code == 200) {
                            this.$store.dispatch('snackbarMessageHandler', "修改成功");
                            this.getSignalsBySymbol(1);
                            this.closeDialog();
                        } else {
                            this.signalLoading = false;
                            this.$store.dispatch('snackbarMessageHandler', data.message);
                        }
                    }, error => {
                        this.$store.dispatch('snackbarMessageHandler', "Error");
                        this.signalLoading = false;
                    });
                }
            },
            // 删除信号
            deleteSignal(signal){
                this.deleteSignalDialog = true;
                this.deleteSignalObj = signal;
            },
            // 删除信号
            deleteSignalConfirm(id){
                if(id != null && id != 'undefined'){
                    let api = config.baseUrl + "/signal/admin/private/deleteSignal?id=" + id;
                    this.$http.get(api).then(res=>{
                        let data = res.data
                        if(data.code == 200){
                            this.getSignalsBySymbol(1);
                            this.$store.dispatch('snackbarMessageHandler', "删除成功");
                        } else{
                            this.$store.dispatch('snackbarMessageHandler', "error");
                        }
                    })
                };
                this.closeDeleteDialog();
            },
            // 关闭弹窗
            closeDialog() {
                this.dialog = false;
                this.signalForm = {};
                this.$refs.signalForm.reset();
                this.signalLoading = false;
            },
            closeDeleteDialog(){
                this.deleteSignalDialog = false;
                this.deleteSignalObj = {};
            },
            // 首字母大写
            toFirstLetterUpperCase(message){
                if (message) {
                    return message.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
                } else {
                    return null;
                }
            },
        }
    }
</script>